
  import { Component, Vue } from 'vue-property-decorator';

  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
  import InspiniaGridItem from '@/components/InspiniaGridItem.vue';

  import { SearchUsersParams } from '@/types/search';
  import { SimpleTableRowItem, SimpleTableHeaderItem } from '@/types/search';
  import { SEARCH_USERS } from '@/store/users';
  import { User } from '@/types/entities';
  import { roles } from '@/types/enums';
  import SearchResultsTable from '@/components/SearchResultsTable.vue';

  @Component({
    components: { SearchResultsTable, HipsterCheckbox, HipsterInput, HipsterSelector, InspiniaGridItem },
    computed: {
      users() {
        return this.$store.state.users.usersList.data
          .map((user: User): SimpleTableRowItem => {
            return {
              to: `/users/${user.id}`,
              line: [
                user.id as any as string,
                user.name,
                user.email,
                user.balance as string,
                user.created_at as string,
                user.picks_count as any as string,
                user.profit as any as string,
                '$' + ((user.picks_count as number * 100) as any as string),
                user.earned === null ? '$0.00' : user.earned as string,
                user.referral_code
              ]
            };
          });
      }
    }
  })
  export default class InsightsList extends Vue {
    public tableHeader: SimpleTableHeaderItem[] = [
      { class: 'col-lg-1', name: 'ID' },
      { class: 'col-lg-1', name: 'Username' },
      { class: 'col-lg-2', name: 'Email' },
      { class: 'col-lg-1', name: 'Balance' },
      { class: 'col-lg-2', name: 'Registration date' },
      { class: 'col-lg-1', name: 'Placed tips' },
      { class: 'col-lg-1', name: 'Profit' },
      { class: 'col-lg-1', name: 'Invested' },
      { class: 'col-lg-1', name: 'Earned' },
      { class: 'col-lg-1', name: 'Referral code' }
    ];

    public filters: SearchUsersParams = {
      query: '',
      per_page: 10,
      page: 1,
      with_earned: 1,
      with_trashed: 1,
      with_count: ['picks'],
      role_id: roles.user
    };

    created() {
      this.search({});
    }

    public search(filter: SearchUsersParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      this.$store.dispatch(SEARCH_USERS, Object.assign(this.filters, filter));
    }
  }
